.searchBar {
  width: 300px;
  max-width: 100%;
  display: flex;
  max-width: 520px;
  border-radius: 4px;
  padding-right: 8px;
  align-items: center;
  background: #F5F5F5;
}
