.input {
  width: 100%;
  border: none;
  height: 32px;
  outline: none;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 4px;
  color: var(--dark-gray-color);
  background-color: #F5F5F5;;
  color: #838484;
}

.input::placeholder {
  color: var(--dark-gray-color);
}
