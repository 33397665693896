.backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  position: fixed;
}

.selectInput {
  position: relative;
  cursor: pointer;
}

.label {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 8px;
  margin-top: 12px;
}

.input {
  width: 100%;
  height: 32px;
  display: flex;
  border-radius: 4px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color:#f7f7f7;
}

.optionsContainer {
  left: 0;
  top: 28px;
  width: 100%;
  padding: 8px 0;
  overflow-y: auto;
  max-height: 100px;
  position: absolute;
  border-radius: 0 0 4px 4px;
  background-color: #f7f7f7;
}

.auxiliaryText {
  font-size: 12px;
  padding: 4px 8px;
  color: #646464;
}

.option {
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
  user-select: none;
  transition: all .1s ease;
  color: #646464;
}

.option:hover {
  background-color: #f7f7f7;
}

.collapseIconContainer {
  width: 24px;
  height: 100%;
  flex-shrink: 0;
  cursor: pointer;
}

.collapseIconOpen,
.collapseIconClosed {
  width: 14px;
  height: 14px;
  margin-top: 8px;
  margin-left: 4px;
  position: relative;
}

.collapseIconOpen span,
.collapseIconClosed span {
  top: 50%;
  width: 50%;
  height: 2px;
  position: absolute;
  transition: all 0.3s ease;
  background-color: #646464;
}

.collapseIconOpen span:first-child,
.collapseIconClosed span:first-child { left: 1px }

.collapseIconOpen span:last-child,
.collapseIconClosed span:last-child { right: 1px }

.collapseIconOpen span:first-child { transform: rotateZ(-30deg) }

.collapseIconOpen span:last-child { transform: rotateZ(30deg) }

.collapseIconClosed span:first-child { transform: rotateZ(30deg) }

.collapseIconClosed span:last-child { transform: rotateZ(-30deg) }
