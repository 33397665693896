.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #838484;
  word-break: break-all
}

.required {
  color: red;
  font-size: 12px;
}