.textarea {
    background-color: #F5F5F5;
    width: 100%;
    min-height: 100px;
    border-radius: 4px;
    resize: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; 
    padding: 10px;
    font-size: 12px;
}
